import React from 'react'
import { Link } from 'gatsby'

import Layout from '../components/Layout'
import SEO from '../components/SEO'

const NotFoundPage = () => (
  <Layout>
    <SEO title="404: Not found" />
    <div className="h-screen flex flex-col justify-center text-center">
      <div className="translate-y-1/2">
        <div className="space-y-4">
          <h1 className="text-6xl font-bold tracking-tight ">
            4
            <span role="img" aria-label="404">
              🤷‍♂️
            </span>
            4
          </h1>
          <p className="leading-8 text-lg">
            Page not found
            <br />
            <Link
              to="/"
              className="text-gray-500 hover:text-gray-600 transition"
            >
              Back to home
            </Link>
          </p>
        </div>
      </div>
    </div>
  </Layout>
)

export default NotFoundPage
